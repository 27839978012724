import { FC, useEffect, useState } from "react";
import loadGMaps from "./GoogleMaps";
import "./css/map.css";

interface Position {
  lat: number;
  lng: number;
}

type CenterControlFunction = (
  controlDiv: HTMLDivElement,
  map: google.maps.Map
) => void;

const Travels: FC = () => {
  const [loaded, setLoaded] = useState<boolean>(false);
  useEffect(() => {
    loadGMaps(() => {
      setLoaded(true);
    });
    let map: google.maps.Map;

    if (loaded) {
      const CenterControl: CenterControlFunction = (controlDiv, map) => {
        var controlUI = document.createElement("div");
        controlUI.id = "controlUI";
        controlUI.title = "Click to go to homepage";
        controlDiv.appendChild(controlUI);

        var controlText = document.createElement("div");
        controlText.id = "controlText";
        controlText.innerHTML = "Home";
        controlUI.appendChild(controlText);

        var projectUI = document.createElement("div");
        projectUI.id = "projectUI";
        projectUI.title = "Click to see my projects";
        controlDiv.appendChild(projectUI);

        var projectText = document.createElement("div");
        projectText.id = "projectText";
        projectText.innerHTML = "Projects";
        projectUI.appendChild(projectText);

        // Setup the click event listeners
        controlUI.addEventListener("click", function () {
          window.location.href = "/";
        });

        projectText.addEventListener("click", function () {
          window.location.href = "projects";
        });
      };

      var positions: [Position, string][] = [
        [
          { lat: 37.8395, lng: -122.2892 },
          '<div id="usa">' +
            '<h1 id="usa" class="usa">usa</h1>' +
            '<div id="bodyContent">' +
            '<a href="https://www.youtube.com/watch?v=FAQLQSK4Dys" target="_blank">' +
            "Quarantine Jam</a> " +
            "</div>" +
            "</div>",
        ],
        [{ lat: 49.246292, lng: -123.116226 }, "canada"],
        [{ lat: 32.522499, lng: -117.046623 }, "mexico"],
        [
          { lat: -12.046374, lng: -77.042793 },
          '<div id="peru">' +
            "<h1>peru</h1>" +
            '<div id="bodyContent">' +
            '<a href="https://photos.app.goo.gl/HASzCAoRQM3Dmkih6" target="_blank">' +
            "music, machu pichu, sand</a> " +
            "</div>" +
            "</div>",
        ],
        [
          { lat: 23.113592, lng: -82.366592 },
          '<div id="cuba">' +
            '<h1 id="cuba" class="cuba">cuba</h1>' +
            '<div id="bodyContent">' +
            '<a href="https://www.youtube.com/watch?v=o_wqSoOMx_U" target="_blank">' +
            "Coconut Taxi in La Habana, Cuba</a> " +
            "</div>" +
            "</div>",
        ],
        [{ lat: 9.934739, lng: -84.087502 }, "costa rica"],
        [
          { lat: -22.908333, lng: -43.196388 },
          '<div id="brazil">' +
            '<h1 id="brazil" class="brazil">brazil</h1>' +
            '<div id="bodyContent">' +
            '<a href="https://www.youtube.com/watch?v=az4NHBoP_68" target="_blank">' +
            "Boat Ride through Iguacu Falls</a> " +
            "</div>" +
            "</div>",
        ],
        [{ lat: -34.603722, lng: -58.381592 }, "argentina"],
        [{ lat: -25.3007, lng: -57.6355 }, "paraguay"],
        [
          { lat: 35.652832, lng: 139.839478 },
          '<div id="japan">' +
            '<h1 id="japan" class="japan">japan</h1>' +
            '<div id="bodyContent">' +
            '<a href="https://www.youtube.com/watch?v=bBE2QpzyIYc" target="_blank">' +
            "Japan with Friends</a> " +
            "</div>" +
            "</div>",
        ],
        [
          { lat: 37.5326, lng: 127.024612 },
          '<div id="south korea">' +
            '<h1 id="south south korea" class="south korea">south korea</h1>' +
            '<div id="bodyContent">' +
            '<a href="https://www.tiktok.com/@k0giii/video/6965326795319102721" target="_blank">' +
            "한옥</a> " +
            "</div>" +
            "</div>",
        ],
        [{ lat: 14.599512, lng: 120.984222 }, "philippines"],
        [{ lat: 1.29027, lng: 103.851959 }, "singapore"],
        [{ lat: 1.0828, lng: 104.0305 }, "indonesia"],
        [
          { lat: 39.92889, lng: 116.38833 },
          '<div id="china">' +
            "<h1>china</h1>" +
            '<div id="bodyContent">' +
            '<a href="https://www.youtube.com/shorts/nH9Y--AG51M" target="_blank">' +
            "dancing shot on ipod touch</a> " +
            "</div>" +
            "</div>",
        ],
        [{ lat: 64.128288, lng: -21.827774 }, "iceland"],
        [{ lat: 53.35014, lng: -6.266155 }, "ireland"],
        [{ lat: 51.5085297, lng: -0.12574 }, "uk"],
        [{ lat: 48.8534088, lng: 2.3487999 }, "france"],
        [{ lat: 52.370216, lng: 4.895168 }, "netherlands"],
        [{ lat: 50.110924, lng: 8.682127 }, "deutschland"],
        [{ lat: 41.8919296, lng: 12.5113297 }, "italy"],
        [{ lat: 46.536804, lng: 7.962639 }, "switzerland"],
        [{ lat: 55.676098, lng: 12.568337 }, "denmark"],
        [{ lat: 59.91273, lng: 10.74609 }, "norway"],
        [{ lat: 59.334591, lng: 18.06324 }, "sweden"],
        [{ lat: 60.1695213, lng: 24.9354496 }, "finland"],
        [
          { lat: 25.033, lng: 121.5654 },
          '<div id="taiwan">' +
            "<h1>taiwan</h1>" +
            '<div id="bodyContent">' +
            '<a href="https://photos.app.goo.gl/Bdg7s3nRA9u6nATA9" target="_blank">' +
            "lanterns and cats</a> " +
            "</div>" +
            "</div>",
        ],
        [{ lat: 13.7563, lng: 100.5018 }, "thailand"],
        [{ lat: 21.0278, lng: 105.8342 }, "vietnam"],
      ];

      map = new window.google.maps.Map(
        document.getElementById("map") as HTMLElement,
        {
          center: { lat: 37.8395, lng: -122.2892 },
          zoom: 2,
          zoomControl: false,
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false,
          restriction: {
            latLngBounds: {
              north: 85,
              south: -85,
              west: -179,
              east: 179,
            },
            strictBounds: true,
          },
          styles: [
            {
              elementType: "geometry",
              stylers: [
                {
                  color: "#212121",
                },
              ],
            },
            {
              elementType: "labels.icon",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#757575",
                },
              ],
            },
            {
              elementType: "labels.text.stroke",
              stylers: [
                {
                  color: "#212121",
                },
              ],
            },
            {
              featureType: "administrative",
              elementType: "geometry",
              stylers: [
                {
                  color: "#757575",
                },
              ],
            },
            {
              featureType: "administrative.country",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#9e9e9e",
                },
              ],
            },
            {
              featureType: "administrative.land_parcel",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "administrative.locality",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#bdbdbd",
                },
              ],
            },
            {
              featureType: "administrative.neighborhood",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "poi",
              elementType: "labels.text",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "poi",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#757575",
                },
              ],
            },
            {
              featureType: "poi.park",
              elementType: "geometry",
              stylers: [
                {
                  color: "#181818",
                },
              ],
            },
            {
              featureType: "poi.park",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#616161",
                },
              ],
            },
            {
              featureType: "poi.park",
              elementType: "labels.text.stroke",
              stylers: [
                {
                  color: "#1b1b1b",
                },
              ],
            },
            {
              featureType: "road",
              elementType: "geometry.fill",
              stylers: [
                {
                  color: "#2c2c2c",
                },
              ],
            },
            {
              featureType: "road",
              elementType: "labels",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "road",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#8a8a8a",
                },
              ],
            },
            {
              featureType: "road.arterial",
              elementType: "geometry",
              stylers: [
                {
                  color: "#373737",
                },
              ],
            },
            {
              featureType: "road.highway",
              elementType: "geometry",
              stylers: [
                {
                  color: "#3c3c3c",
                },
              ],
            },
            {
              featureType: "road.highway.controlled_access",
              elementType: "geometry",
              stylers: [
                {
                  color: "#4e4e4e",
                },
              ],
            },
            {
              featureType: "road.local",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#616161",
                },
              ],
            },
            {
              featureType: "transit",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#757575",
                },
              ],
            },
            {
              featureType: "water",
              elementType: "geometry",
              stylers: [
                {
                  color: "#000000",
                },
              ],
            },
            {
              featureType: "water",
              elementType: "labels.text",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "water",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#3d3d3d",
                },
              ],
            },
          ],
        }
      );

      var centerControlDiv = document.createElement("div");
      CenterControl(centerControlDiv, map);

      map.controls[window.google.maps.ControlPosition.TOP_CENTER].push(
        centerControlDiv
      );

      for (var i = 0; i < positions.length; i++) {
        let marker = new window.google.maps.Marker({
          position: positions[i][0],
          map: map,
        });

        var infowindow = new window.google.maps.InfoWindow();

        window.google.maps.event.addListener(
          marker,
          "click",
          (function (marker, i) {
            return function () {
              infowindow.setContent(positions[i][1]);
              infowindow.open(map, marker);
            };
          })(marker, i)
        );
      }
    }
  }, [loaded]);

  return <div id="map"></div>;
};

export default Travels;
