import "./css/style.css";
import { FC } from "react";

const Projects: FC = () => {
  return (
    <div className="html">
      <div id="wrapper">
        <div id="title">
          <a href="/">Eugene Lee</a>
        </div>
        <div id="inf">
          <span id="duolingo">
            <b>
              <a
                href="https://www.duolingo.com/profile/sheushin"
                target="_blank"
                rel="noreferrer"
              >
                duolingo
              </a>
            </b>
          </span>
          <div id="one">Jul 2020 - Present</div>
          <br />
          <span id="shadow">
            <b>
              <a
                href="https://github.com/Kaist-ICLab/speech_shadowing_bot"
                target="_blank"
                rel="noreferrer"
              >
                speech shadowing bot
              </a>
            </b>
          </span>
          <div id="one">Feb 2023 - May 2024</div>
          <br />
          <span id="leetcode">
            <b>
              <a
                href="https://leetcode.com/krub/"
                target="_blank"
                rel="noreferrer"
              >
                leetcode
              </a>
            </b>
          </span>
          <div id="one">Jan 2023 - May 2024</div>
          <br />
          <span id="parcel">
            <b>
              <a
                href="https://github.com/eugenlee/parceltracking_v2/tree/ail"
                target="_blank"
                rel="noreferrer"
              >
                parcel tracking
              </a>
            </b>
          </span>
          <div id="one">November 2023</div>
          <br />
          <span id="strasb">
            <b>
              <a
                href="https://www.tally.xyz/gov/web3atkaist-2023/proposal/19363343624449512003859703095119427253163935390615042240894878141681517417169?chart=0"
                target="_blank"
                rel="noreferrer"
              >
                strasb
              </a>
            </b>
          </span>
          <div id="one">May 2023</div>
          <br />
          <span id="nec">
            <b>
              <a
                href="https://github.com/eugenlee/NEC"
                target="_blank"
                rel="noreferrer"
              >
                nec redesign
              </a>
            </b>
          </span>
          <div id="one">June 2020</div>
          <br />
          <span id="nba">
            <b>
              <a
                href="https://github.com/eugenlee/nba-players"
                target="_blank"
                rel="noreferrer"
              >
                basketball api
              </a>
            </b>
          </span>
          <div id="one">May 2020</div>
          <br />
          <span id="wsb">
            <b>
              <a
                href="https://github.com/eugenlee/eugenlee.github.io"
                target="_blank"
                rel="noreferrer"
              >
                bullish or bearish
              </a>
            </b>
          </span>
          <div id="one">February 2020</div>
          <br />
          <span id="bus">
            <b>
              <a
                href="https://github.com/eugenlee/bus"
                target="_blank"
                rel="noreferrer"
              >
                bus
              </a>
            </b>
          </span>
          <div id="one">February 2020</div>
          <br />
          <span id="mailbox">
            <b>
              <a
                href="https://github.com/eugenlee/mailboxesmore"
                target="_blank"
                rel="noreferrer"
              >
                mailbox
              </a>
            </b>
          </span>
          <div id="one" style={{ paddingBottom: "30px" }}>
            October 2019
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
