import "./css/style.css";
import TagManager from "react-gtm-module";

const Home = () => {
  const handleLinkClick = (label: any) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "link_click",
        link_label: label,
      },
    });
  };

  return (
    <div className="html">
      {/* <Sidebar /> */}
      <div id="wrapper">
        <div id="title">
          <a href="/" onClick={() => handleLinkClick("home")}>
            Eugene Lee
          </a>
        </div>
        <div className="inf">
          Engineer & Comedian, currently based in San Francisco.
          <br />
          <br />
          Currently at<span> </span>
          <span id="retool">
            <a
              href="https://retool.com/"
              target="_blank"
              rel="noreferrer"
              onClick={() => handleLinkClick("retool")}
            >
              Retool<sup>1</sup>
            </a>
          </span>
          <span className="noHover">. Previously at </span>
          <span id="kaist">
            <a
              href="https://www.kaist.ac.kr/en/"
              target="_blank"
              rel="noreferrer"
              onClick={() => handleLinkClick("KAIST")}
            >
              KAIST<sup>2</sup>
            </a>
          </span>
          <div>
            <span id="fintech">
              <a
                href="https://clearwateranalytics.com/"
                target="_blank"
                rel="noreferrer"
                onClick={() => handleLinkClick("clearwater")}
              >
                Clearwater Analytics<sup>1</sup>
              </a>
            </span>
            ,{" "}
            <span id="ucd">
              <a
                href="https://che.engineering.ucdavis.edu/"
                target="_blank"
                rel="noreferrer"
                onClick={() => handleLinkClick("ucdavis")}
              >
                UC Davis<sup>3</sup>
              </a>
            </span>
            ,{" "}
            <span id="adtech">
              <a
                href="https://www.chartboost.com/"
                target="_blank"
                rel="noreferrer"
                onClick={() => handleLinkClick("chartboost")}
              >
                Chartboost<sup>1</sup>
              </a>
            </span>
            ,{" "}
            <span id="biotech">
              <a
                href="https://www.zymergen.com/"
                target="_blank"
                rel="noreferrer"
                onClick={() => handleLinkClick("zymergen")}
              >
                Zymergen<sup>4</sup>
              </a>
            </span>
            {"."}
          </div>
        </div>
        <ol id="jobs">
          <li className="pr">Engineer</li>
          <li className="pr">MS in Computer Science</li>
          <li className="pr">BS in Chemical Engineering</li>
          <li className="pr">R&D</li>
        </ol>
        <div id="foot">
          <span className="noHover" id="copyright">
            © Eugene Lee 2024
          </span>
          <div id="link">
            <span id="linkedin" className="footerItem">
              <a
                href="https://www.linkedin.com/in/eugenelukelee/"
                rel="noreferrer"
                onClick={() => handleLinkClick("linkedin")}
              >
                <i className="fab fa-linkedin silver-link"></i>
              </a>
            </span>
            <span id="strava" className="footerItem">
              <a
                href="https://www.strava.com/athletes/122002197"
                rel="noreferrer"
                onClick={() => handleLinkClick("strava")}
              >
                <i className="fab fa-brands fa-strava"></i>
              </a>
            </span>
            <span id="leetcode" className="footerItem">
              <a
                href="/projects"
                rel="noreferrer"
                onClick={() => handleLinkClick("projects")}
              >
                Projects
              </a>
            </span>
            <span id="travels" className="footerItem">
              <a
                href="/travels"
                rel="noreferrer"
                onClick={() => handleLinkClick("travels")}
              >
                Travels
              </a>
            </span>
            <span id="places" className="footerItem">
              <a
                href="https://placestheapp.com/profile/dcf3ce26"
                rel="noreferrer"
                onClick={() => handleLinkClick("places")}
              >
                Places
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
